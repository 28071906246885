import React from "react";
import AuthorComponent from "../components/authorComponent";
import SocialMediaComponent from "../components/socialMediaComponent";
import { styled } from "@mui/material/styles";

const AuthorAndSocialMediaComponent = (props) => {
  const { isSmall = false } = props;

  return (
    <AuthorSocialMediaContainer>
      <AuthorComponent isSmall={isSmall}></AuthorComponent>
      <SocialMediaComponent></SocialMediaComponent>
    </AuthorSocialMediaContainer>
  );
};

export default AuthorAndSocialMediaComponent;

const AuthorSocialMediaContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "wrap",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));
