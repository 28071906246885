import React from "react";
import Layout from "../styles/layout";
import { Typography, Link, Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Seo from "../components/seo";
import AffiliateView from "../components/affiliateView";
import AuthorAndSocialMediaComponent from "../components/authorAndSocialMediaComponent";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { SubHeadline } from "../styles/globalStyles";

function inlineLink(url, title) {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {title}
    </Link>
  );
}

export default function Sponsorship({ props, location }) {
  const data = useStaticQuery(graphql`
    query {
      sponsorEssentialDeveloper: file(
        relativePath: { eq: "img/affiliate/logo-essential-developer.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(height: 60)
        }
      }
      sponsorProxyman: file(
        relativePath: { eq: "img/affiliate/logo-proxyman.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(height: 60)
        }
      }
      sponsorGlassfy: file(
        relativePath: { eq: "img/affiliate/logo-glassfy.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(height: 60)
        }
      }
      sponsorCodemagic: file(
        relativePath: { eq: "img/affiliate/logo-codemagic.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(height: 60)
        }
      }
      sponsorFrankThamel: file(
        relativePath: { eq: "img/affiliate/logo-frankthamel.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(height: 60)
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <Seo
        title="Sponsorship - iOS development on tanaschita.com"
        description="With currently over 35000 unique visitors every month, tanaschita.com
        is publishing a new article every week and is constantly growing in
        the iOS and Swift community - which may provide a great audience for
        you as a sponsor. Your exclusive advertisement will be placed on the homepage and in
        every article of this site."
      />
      <Container>
        <Typography variant="h1">Sponsorship</Typography>
        <Typography>
          With currently over <b>30000 unique visitors every month</b>,
          tanaschita.com is publishing a new article every week and is
          constantly growing in the iOS and Swift community - which may provide
          a great audience for you as a sponsor.
        </Typography>
        <SubHeadline>Advertisement example</SubHeadline>
        <Typography>
          Your exclusive advertisement will be placed on the homepage and in
          every article of this site.
        </Typography>
        <AffiliateView type="sponsor-proxyman"></AffiliateView>
        <Box height="24px"></Box>
        <SubHeadline>Packages</SubHeadline>
        <Typography variant="h4">4 weeks of sponsorship - 499€</Typography>
        <Typography>
          - exclusive advertisement on the homepage and in every article of this
          site for 4 weeks
        </Typography>
        <Typography variant="h4">1 week of sponsorship - 149€</Typography>
        <Typography>
          - exclusive advertisement on the homepage and in every article of this
          site for 1 week
        </Typography>
        <SubHeadline>Contact</SubHeadline>
        <Typography>
          If you are interested in sponsoring tanaschita.com, contact me at{" "}
          {inlineLink("mailto:tanaschita@gmail.com", "tanaschita@gmail.com")}{" "}
          and I will get back to you with more details and available dates.
        </Typography>
        <Box height="24px"></Box>
        <AuthorAndSocialMediaComponent></AuthorAndSocialMediaComponent>
        <SubHeadline>Previous sponsorship partners</SubHeadline>
        <SponsorshipPartnerImagesContainer>
          <SponsorshipPartnerImageContainer>
            <Link
              href="https://proxyman.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.sponsorProxyman.childImageSharp.logo}
                alt="Proxyman logo"
                title="Proxyman"
              />
            </Link>
          </SponsorshipPartnerImageContainer>
          <SponsorshipPartnerImageContainer>
            <Link
              href="https://iosdevlibrary.lemonsqueezy.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.sponsorFrankThamel.childImageSharp.logo}
                alt="Book cover"
                title="Scalable & Accessible Mobile Design Systems by Frank Thamel"
              />
            </Link>
          </SponsorshipPartnerImageContainer>
          <SponsorshipPartnerImageContainer>
            <Link
              href="https://codemagic.io/ios-continuous-integration?utm_source=tanaschita&utm_medium=tanaschita&utm_campaign=ios_sponsorship"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.sponsorCodemagic.childImageSharp.logo}
                alt="codemagic logo"
                title="Codemagic"
              />
            </Link>
          </SponsorshipPartnerImageContainer>
          <SponsorshipPartnerImageContainer>
            <Link
              href="https://iosacademy.essentialdeveloper.com/p/ios-architect-crash-course-tans2m54/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.sponsorEssentialDeveloper.childImageSharp.logo}
                alt="essential developer logo"
                title="Essential Developer"
              />
            </Link>
          </SponsorshipPartnerImageContainer>
          <SponsorshipPartnerImageContainer>
            <Link
              href="https://www.glassfy.io?utm_source=partn&utm_medium=web&utm_campaign=tanaschita"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.sponsorGlassfy.childImageSharp.logo}
                alt="glassfy logo"
                title="Glassfy"
              />
            </Link>
          </SponsorshipPartnerImageContainer>
        </SponsorshipPartnerImagesContainer>
      </Container>
    </Layout>
  );
}

const SponsorshipPartnerImageContainer = styled("div")(({ theme }) => ({
  marginRight: "30px",
  marginBottom: "10px",
}));

const SponsorshipPartnerImagesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "10px",
  alignItems: "center",
}));
